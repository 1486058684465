<template>
  <span
    v-if="adressen?.length"
    :class="{
      'inline-flex': adressen.length === 1,
      'gap-2': !kissAndRide,
      'gap-3': kissAndRide,
    }"
  >
    <a
      :href="link"
      :disabled="store.getters.isKantoorPermission"
      rel="noopener noreferrer nofollow"
      target="_blank"
      class="group"
      @click.prevent="openLink(link)"
    >
      <slot>
        <i v-if="icon" :class="icon" class="w-5"></i>
        <span
          v-if="adres && !hideAdres"
          class="group-hover:underline"
          :class="{
            'text-orange-500 font-bold bg-red-100 px-2 text-left': showGeocodeStatus && (!adres.lng || !adres.lat),
            'text-green-600 font-bold': showGeocodeStatus && (adres.lng && adres.lat),
          }"
        >
          <span v-if="findLuchthaven(adres)">Luchthaven {{ findLuchthaven(adres)?.name }}</span>
          <span v-else-if="findStation(adres)">Station {{ findStation(adres)?.name }}</span>
          <span v-else-if="findHaven(adres)">Haven {{ findHaven(adres)?.name }}</span>
          <span v-else-if="findHotel(adres)">Hotel {{ findHotel(adres)?.name }}</span>
          <span v-else>{{ adres?.adres || adres }}</span>
        </span>
        <span v-if="showGeocodeStatus" class="tracking-tighter">
          <i v-if="adres.lng" class="fas fa-check text-xs text-green-600 rotate-2"></i>
          <i v-else class="fas fa-times text-xs text-red-600"></i>
          <i v-if="adres.lat" class="fas fa-check text-xs text-green-600 rotate-2"></i>
          <i v-else class="fas fa-times text-xs text-red-600"></i>
        </span>
      </slot>
    </a>

    <button
      v-if="adressen.length === 1"
      class="bg-dashboard-dark text-white rounded text-xs px-1 opacity-60 hover:opacity-100 w-6 text-center"
      :disabled="isCopying"
      @click="handleCopy"
    >
      <i class="fas text-xs" :class="{ 'fa-copy': !isCopying, 'fa-spinner': isCopying }"></i>
    </button>

    <a
      v-if="kissAndRidelink"
      :href="kissAndRidelink"
      rel="noopener noreferrer nofollow"
      target="_blank"
      class="bg-dashboard-dark text-white rounded text-base px-1 opacity-60 hover:opacity-100 w-6 text-center"
    >
      <i class="fas fa-parking"></i>
    </a>
  </span>
</template>

<script setup>
import { computed, defineProps, ref } from 'vue'
import { useStore } from 'vuex'
import { findHaven, findHotel, findLuchthaven, findStation } from '@taxiboeken-v2/utilities-berekening'

import useParsedAdressen from '@/hooks/useParsedAdressen'

const props = defineProps({
  labelIndex: Number,
  icon: String,
  showGeocodeStatus: Boolean,
  kissAndRide: Boolean,
  hideAdres: Boolean,
  allowWaze: Boolean,
  adres: {
    type: [Array, String, Object],
    required: true,
  },
})

const store = useStore()
const adressen = useParsedAdressen(props)

const toGoogleLink = listOfAdressen => {
  if (!listOfAdressen?.length) return null
  let result = 'https://www.google.com/maps/dir/'
  listOfAdressen.forEach(adres => {
    const adresString = (typeof adres === 'string' ? adres : adres.adres) || ''
    if (adresString) result += `/${encodeURIComponent(adresString)}`
  })
  return result
}

const toWazeLink = listOfAdressen => {
  if (!listOfAdressen?.length) return null
  // Waze kan enkel 1 adres per keer navigeren
  const adres = listOfAdressen[0]
  const result = new URL(`https://waze.com/ul`)
  if (adres.lng && adres.lat) {
    result.searchParams.set('ll', `${adres.lat},${adres.lng}`)
    result.searchParams.set('navigate', 'yes')
  }
  return result.toString()
}

const toNavigationLink = listOfAdressen => {
  if (!listOfAdressen?.length) return null
  if (listOfAdressen.length > 1) return toGoogleLink(listOfAdressen)
  if (store.getters.isWazePreferred && props.allowWaze) return toWazeLink(listOfAdressen)
  return toGoogleLink(listOfAdressen)
}

const link = computed(() => {
  if (store.getters.isKantoorPermission) {
    return '#adres'
  }
  const listOfAdressen = adressen.value.map(adres => adres)
  return toNavigationLink(listOfAdressen)
})

const kissAndRidelink = computed(() => {
  if (!props.kissAndRide) return null
  let isLuchthavenFound = false

  const listOfAdressen = []
  adressen.value.forEach(adres => {
    const luchthaven = findLuchthaven(adres)
    if (luchthaven?.name) {
      isLuchthavenFound = true
      listOfAdressen.push(luchthaven?.kissAndRide || `Kiss & Ride Luchthaven ${luchthaven?.name}`)
      return
    }
    listOfAdressen.push(adres)
  })

  if (isLuchthavenFound) return toNavigationLink(listOfAdressen)
  return null
})

const adresTest = computed(() => props.adres?.adres || props.adres)

const openAnchorLink = link => {
  const a = document.createElement('a')
  a.href = link
  a.target = '_blank'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

const openLink = async link => {
  if (link && link[0] !== '#') {
    if (adressen.value.length === 1) await handleCopy()
    openAnchorLink(link)
  }
}

const isCopying = ref(false)
const handleCopy = async e => {
  if (typeof e?.preventDefault === 'function') {
    e.preventDefault()
  }

  const text = adresTest.value
  if (typeof text !== 'string') return

  isCopying.value = true

  try {
    await navigator.clipboard.writeText(text)
  } catch {
    try {
      const el = document.createElement('textarea')
      el.value = text
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    } catch {
      // Nothing
    }
  }

  setTimeout(() => {
    isCopying.value = false
  }, 500)
}
</script>
